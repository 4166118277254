import _ from 'lodash'

class ColumnsUtils {
  getValue(obj, path) {
    const paths = path.split('.').filter(p => p)
    return _.get(obj, paths, undefined)
  }

  setValue(obj, path, value) {
    return _.set(obj, path, value)
  }

  renderFloat(value) {
    if (value != undefined && value !== '')
      return Number.parseFloat(value).toFixed(2)
    return ''
  }

  renderBoolean(value) {
    if (value == true || value == 'true') return 'Yes'
    if (value == false || value == 'false') return 'No'
    return ''
  }

  renderDate(value) {
    if (value != undefined && value != '') return formatDate(value)
    return ''
  }

  renderDateTime(value) {
    if (value != undefined && value != '') {
      if (typeof value === 'string') {
        value = value.replace(' UTC', '+0000')
        value = new Date(value)
      }

      return value.toUTCString().slice(0, -4)
    }
    return ''
  }

  renderUrl(value) {
    if (value != undefined && value != '')
      return `<a style="color: var(--bs-link-color);" href="${value}" target="_blank">Open</a>`
    return ''
  }

  renderShow(value) {
    if (value != undefined && value != '')
      return `<a style="color: var(--bs-link-color); font-weight: bold;" href="${value}" target="_blank">Show</a>`
    return ''
  }

  renderCoordinates(value) {
    const latitude = value && value[0] ? value[0] : ''
    const longitude = value && value[1] ? value[1] : ''
    const altitude = value && value[2] ? value[2] : ''
    let latitudeFormatted = Number.parseFloat(latitude)
    let longitudeFormatted = Number.parseFloat(longitude)
    let altitudeFormatted = Number.parseFloat(altitude)
    latitudeFormatted = Number.isNaN(latitudeFormatted)
      ? 'Undefined Latitude'
      : latitudeFormatted.toFixed(5)
    longitudeFormatted = Number.isNaN(longitudeFormatted)
      ? 'Undefined Longitude'
      : longitudeFormatted.toFixed(5)
    altitudeFormatted = Number.isNaN(altitudeFormatted)
      ? 'Undefined Altitude'
      : `${altitudeFormatted.toFixed(2)}m`
    return `${latitudeFormatted}, ${longitudeFormatted} (${altitudeFormatted})`
  }

  renderDateUrl(obj) {
    if (obj && obj.date && obj.url) {
      const date = formatDate(obj.date)
      const url = `<a style="color: var(--bs-link-color);" href="${obj.url}" target="_blank">Open</a>`
      return `${date} ${url}`
    }
    return ''
  }

  renderEdit(value) {
    if (value != undefined && value != '')
      return `<i class="bi bi-pencil">\
          <a class="edit" href="${value}">\
            EDIT\
          </a>\
        </i>`
    return ''
  }

  renderDelete(value) {
    if (value != undefined && value != '')
      return `<i class="bi bi-trash">\
          <a\
            class="delete"\
            onclick="return confirm('Are you sure?')"
            data-method="delete"\
            rel="nofollow"\
            href="${value}">\
            DELETE\
          </a>\
        </i>`
    return ''
  }

  valueFormatter(value, callback) {
    if (Array.isArray(value)) {
      const values = value.map(value => callback(value))
      return values.join(', ')
    } else return callback(value)
  }

  cellRenderer(value, callback) {
    if (Array.isArray(value)) {
      const values = value.map(value => callback(value))
      return values.join('<br>')
    } else return callback(value)
  }

  buildSingleColumnStructureFromGridView(column, aColumnsMetadataMap) {
    const version = localStorage.getItem('version') || '1'

    const field = column.field
    const metadataCol = aColumnsMetadataMap[field]
    const combCol = _.merge({ ...metadataCol }, column)
    const description = combCol.description
      ? combCol.description
      : combCol.title
    const sortableField = !combCol.notSortable
    const retColumnDef = {
      colId: field,
      headerName: combCol.title,
      field: combCol.field,
      isCustom: combCol.isCustom,
      valueType: combCol.valueType,
      dateField: combCol.dateField,
      sortable: sortableField ? true : false,
      sortableField: combCol.sortableField,
      stringify: combCol.stringify,
      valueFormatter: params => {
        return this.valueFormatter(params.value, value => value)
      },
      width: combCol.width,
      editable: !!combCol.editable,
      headerTooltip: description,
      createApi: combCol.create_api,
      customFieldId: combCol.id
    }

    if (combCol.isCustom) {
      if (!combCol.valueType) {
        retColumnDef.valueType = 'string'
      }
      return retColumnDef
    }
    if (!combCol.valueType) {
      console.error(
        'buildSingleColumnStructureFromGridView - failed to manage column from response : ',
        combCol
      )
      return retColumnDef
    }
    const isSelectOption = combCol.valueType.startsWith('selectOption')
    const isSelectMultipleOptions = combCol.valueType.startsWith(
      'selectMultipleOptions'
    )

    let valueGetter
    if (combCol.field.includes('.')) {
      valueGetter = params => {
        const value = this.getValue(params.data, combCol.field)
        return value
      }
    }

    retColumnDef.valueGetter = valueGetter

    const optionsMap = {}
    const optionsArray = []

    if (isSelectOption) {
      // retColumnDef.enableRowGroup = true
      retColumnDef.keyCreator = params => {
        return optionsMap[field][params.value] || params.value
      }
    }
    if (isSelectOption || isSelectMultipleOptions) {
      optionsMap[field] = {}
      let options = combCol.options
      if (typeof combCol.options == 'string')
        options = JSON.parse(combCol.options)
      options.forEach(option => {
        optionsMap[field][option.id] = option.name
        optionsArray.push({
          ...option,
          value: option.name,
          included: true
        })
      })

      if (version === '1') {
        retColumnDef.cellEditor = 'multipleOptionsEditor'
      }
      retColumnDef.filter = 'aiSetColumnFilter'
      retColumnDef.availableOptions = optionsArray
      retColumnDef.cellEditorParams = params => {
        let valueEx = null
        if (!isSelectMultipleOptions && !!params.value)
          valueEx = {
            id: params.value,
            name: optionsMap[field][params.value]
          }
        return {
          options: optionsArray,
          optionsFilter: combCol.optionsFilter,
          isSelectMultipleOptions,
          valueEx,
          row: params.data
        }
      }

      if (isSelectMultipleOptions) {
        retColumnDef.isSelectMultipleOptions = true

        retColumnDef.valueFormatter = params => {
          try {
            let values = []
            if (params.value != undefined && params.value != '') {
              let tmpValues = params.value
              if (typeof tmpValues == 'string')
                tmpValues = JSON.parse(tmpValues)
              if (Array.isArray(tmpValues) && tmpValues.length > 0) {
                tmpValues.forEach(value => {
                  if (!_.isNil(value)) {
                    const computedValue = optionsMap[field][value]
                    if (!_.isNil(computedValue)) values.push(computedValue)
                  }
                })
              }
            }
            return values.join(', ')
          } catch (error) {
            console.error(
              `Could not format ${params.value} as multiple options: ${error}`
            )
            return ''
          }
        }
      } else {
        retColumnDef.valueFormatter = params => {
          if (params.value != undefined && params.value != '')
            return optionsMap[field][params.value]
          return ''
        }
      }
    }

    switch (combCol.valueType) {
      case 'float':
        retColumnDef.filter = 'agNumberColumnFilter'
        retColumnDef.valueFormatter = params => {
          return this.valueFormatter(params.value, this.renderFloat)
        }
        break
      case 'integer':
        retColumnDef.filter = 'agNumberColumnFilter'
        break
      case 'string':
        retColumnDef.filter = 'agTextColumnFilter'
        break
      case 'text':
        retColumnDef.filter = 'agTextColumnFilter'
        if (version === '1') {
          retColumnDef.cellEditor = 'agLargeTextCellEditor'
        }
        retColumnDef.cellEditorParams = { maxLength: 100000 }
        break
      case 'boolean':
        retColumnDef.valueFormatter = params => {
          return this.valueFormatter(params.value, this.renderBoolean)
        }
        if (version === '1') {
          retColumnDef.cellEditor = 'multipleOptionsEditor'
        }
        retColumnDef.filter = 'aiSetColumnFilter'
        retColumnDef.availableOptions = [
          { id: true, value: 'Yes', name: 'Yes', included: true },
          { id: false, value: 'No', name: 'No', included: true }
        ]

        retColumnDef.cellEditorParams = params => {
          const rendredBoolean = this.renderBoolean(params.value)
          let valueEx = null
          switch (rendredBoolean) {
            case 'Yes':
              valueEx = { id: true, name: rendredBoolean }
              break
            case 'No':
              valueEx = { id: false, name: rendredBoolean }
              break
            default:
              break
          }
          return {
            options: retColumnDef.availableOptions,
            isSelectMultipleOptions: false,
            valueEx
          }
        }
        break
      case 'date':
        retColumnDef.valueFormatter = params => {
          return this.valueFormatter(params.value, this.renderDate)
        }
        if (version === '1') {
          retColumnDef.cellEditor = 'dateEditor'
        }
        retColumnDef.filter = 'dateColumnFilter'
        break
      case 'datetime':
        retColumnDef.valueFormatter = params => {
          return this.valueFormatter(params.value, this.renderDateTime)
        }
        if (version === '1') {
          retColumnDef.cellEditor = 'dateEditor'
        }
        retColumnDef.filter = 'dateColumnFilter'
        break
      case 'url':
        retColumnDef.cellRenderer = params => {
          return this.cellRenderer(params.value, this.renderUrl)
        }
        retColumnDef.filter = 'aiSetColumnFilter'
        retColumnDef.availableOptions = [
          {
            id: true,
            value: 'Not Empty',
            name: 'Not Empty',
            included: true
          }
        ]
        break
      case 'show':
        retColumnDef.cellRenderer = params => {
          return this.cellRenderer(params.value, this.renderShow)
        }
        retColumnDef.filter = 'aiSetColumnFilter'
        retColumnDef.availableOptions = [
          {
            id: true,
            value: 'Not Empty',
            name: 'Not Empty',
            included: true
          }
        ]
        break
      case 'edit':
        retColumnDef.cellRenderer = params => {
          return this.cellRenderer(params.value, this.renderEdit)
        }
        retColumnDef.valueFormatter = () => 'Edit'
        break
      case 'delete':
        retColumnDef.cellRenderer = params => {
          return this.cellRenderer(params.value, this.renderDelete)
        }
        retColumnDef.valueFormatter = () => 'Delete'
        break
      case 'dateUrl':
        retColumnDef.cellRenderer = params => {
          return this.cellRenderer(params.value, this.renderDateUrl)
        }
        retColumnDef.valueFormatter = params => {
          return this.valueFormatter(params.value?.url, value => value)
        }
        retColumnDef.filter = 'dateColumnFilter'
        break
      case 'coordinates':
        retColumnDef.valueFormatter = params => {
          if (Array.isArray(params.value)) {
            if (params.value.length > 0 && Array.isArray(params.value[0]))
              return this.valueFormatter(params.value, this.renderCoordinates)
            return this.renderCoordinates(params.value)
          }
          return ''
        }
        retColumnDef.width = 220
        retColumnDef.sortable = false
        retColumnDef.filter = 'aiSetColumnFilter'
        retColumnDef.availableOptions = [
          {
            id: true,
            value: 'Not Empty',
            name: 'Not Empty',
            included: true
          }
        ]
        if (version === '1') {
          retColumnDef.cellEditor = 'coordinatesEditor'
        }
        break
    }

    return retColumnDef
  }
}

const columnsUtils = new ColumnsUtils()
export default columnsUtils
